import { SvgIconProps } from '@/types/helpers'

export function CheckCircleFilled(props: SvgIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="#23AC8A"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M12 22C17.5228 22 22 17.5228 22 12C22 10.342 21.5965 8.77819 20.8823 7.4015C20.7327 7.11315 20.348 7.06624 20.1183 7.29594L12.4142 15C11.6332 15.781 10.3668 15.7811 9.58579 15L6.79289 12.2071C6.40237 11.8166 6.40237 11.1834 6.79289 10.7929C7.18342 10.4024 7.81658 10.4024 8.20711 10.7929L11 13.5858L19.0214 5.57007C19.211 5.38054 19.2183 5.07414 19.0276 4.88571C17.2215 3.10148 14.7394 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        fill="currentFill"
      />
    </svg>
  )
}
