import { cn } from '@/app/_primitives/utils/cn'
import { SvgIconProps } from '@/types/helpers'

export function LoadingSpinner({ className, ...props }: SvgIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="#D9DADB"
      xmlns="http://www.w3.org/2000/svg"
      className={cn('animate-spin', className)}
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C12.5523 2 13 2.44772 13 3V5C13 5.55228 12.5523 6 12 6C11.4477 6 11 5.55228 11 5V3C11 2.44772 11.4477 2 12 2Z"
        fill="currentFill"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 18C12.5523 18 13 18.4477 13 19V21C13 21.5523 12.5523 22 12 22C11.4477 22 11 21.5523 11 21V19C11 18.4477 11.4477 18 12 18Z"
        fill="currentFill"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.92894 4.92893C5.31946 4.53841 5.95263 4.53841 6.34315 4.92893L7.75737 6.34315C8.14789 6.73367 8.14789 7.36684 7.75737 7.75736C7.36684 8.14788 6.73368 8.14788 6.34315 7.75736L4.92894 6.34315C4.53841 5.95262 4.53841 5.31946 4.92894 4.92893Z"
        fill="currentFill"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.2426 16.2426C16.6332 15.8521 17.2663 15.8521 17.6569 16.2426L19.0711 17.6569C19.4616 18.0474 19.4616 18.6805 19.0711 19.0711C18.6805 19.4616 18.0474 19.4616 17.6569 19.0711L16.2426 17.6569C15.8521 17.2663 15.8521 16.6332 16.2426 16.2426Z"
        fill="currentFill"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 12C2 11.4477 2.44772 11 3 11L5 11C5.55228 11 6 11.4477 6 12C6 12.5523 5.55228 13 5 13L3 13C2.44772 13 2 12.5523 2 12Z"
        fill="currentFill"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 12C18 11.4477 18.4477 11 19 11L21 11C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13L19 13C18.4477 13 18 12.5523 18 12Z"
        fill="currentFill"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.92893 19.0711C4.5384 18.6805 4.5384 18.0474 4.92893 17.6569L6.34314 16.2426C6.73366 15.8521 7.36683 15.8521 7.75735 16.2426C8.14788 16.6332 8.14788 17.2663 7.75735 17.6569L6.34314 19.0711C5.95261 19.4616 5.31945 19.4616 4.92893 19.0711Z"
        fill="currentFill"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.2426 7.75736C15.8521 7.36684 15.8521 6.73367 16.2426 6.34315L17.6569 4.92893C18.0474 4.53841 18.6805 4.53841 19.0711 4.92893C19.4616 5.31946 19.4616 5.95262 19.0711 6.34315L17.6569 7.75736C17.2663 8.14788 16.6332 8.14788 16.2426 7.75736Z"
        fill="currentFill"
      />
    </svg>
  )
}
