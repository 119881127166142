import { SvgIconProps } from '@/types/helpers'

export function WarningFilled(props: SvgIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="#FDCC53"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.39358 4.57367C10.5445 2.55418 13.4556 2.55418 14.6065 4.57367L21.4115 16.5146C22.5512 18.5146 21.107 21 18.805 21H5.19508C2.89316 21 1.44888 18.5146 2.58862 16.5146L9.39358 4.57367ZM12 14C12.5523 14 13 13.5523 13 13V8.00001C13 7.44773 12.5523 7.00001 12 7.00001C11.4477 7.00001 11 7.44773 11 8.00001V13C11 13.5523 11.4477 14 12 14ZM12 18C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18Z"
        fill="currentFill"
      />
    </svg>
  )
}
