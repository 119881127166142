import { CheckCircleFilled } from '@/app/_primitives/icons/CheckCircleFilled'
import { CloseCircleFilled } from '@/app/_primitives/icons/CloseCircleFilled'
import { InfoCircleFilled } from '@/app/_primitives/icons/InfoCircleFilled'
import { LoadingSpinner } from '@/app/_primitives/icons/LoadingSpinner'
import { WarningFilled } from '@/app/_primitives/icons/WarningFilled'
import { cn } from '@/app/_primitives/utils/cn'
import React from 'react'
import rht, { ToastOptions } from 'react-hot-toast'

const defaultOptions: ToastOptions = {
  position: 'top-right',
  duration: 2000,
}

type ToastType = 'loading' | 'success' | 'error' | 'info' | 'warning'

type Message = React.ReactNode | { content: React.ReactNode; title: string }

type CustomToasterProps = {
  id: string
  visible: boolean
  type: ToastType
  message: React.ReactNode
  className?: string
  customIcon?: React.ReactNode
  customTitle?: string
}

const Icons: {
  [key in ToastType]: React.ReactNode
} = {
  loading: <LoadingSpinner className={'size-24 shrink-0'} />,
  success: <CheckCircleFilled className={'size-24 shrink-0'} />,
  error: <CloseCircleFilled className={'size-24 shrink-0'} />,
  info: <InfoCircleFilled className={'size-24 shrink-0'} />,
  warning: <WarningFilled className={'size-24 shrink-0'} />,
}

const Titles: {
  [key in ToastType]: string
} = {
  loading: 'Loading',
  success: 'Success',
  error: 'Error',
  info: 'Info',
  warning: 'Warning',
}

function CustomToaster({
  id,
  visible,
  type,
  message,
  className,
  customIcon,
  customTitle,
}: CustomToasterProps) {
  const icon = type === 'success' ? customIcon || Icons[type] : Icons[type]
  const title = customTitle || Titles[type]

  return (
    <div
      className={cn(
        'relative bg-white shadow-md rounded-20 p-20',
        'flex flex-row items-start gap-8 w-387',
        {
          'animate-tenter': visible,
          'animate-tleave': !visible,
        },
        className,
      )}>
      {icon}

      {type === 'loading' && <p className={'c-b3 text-[#1A1A1A]'}>{message}</p>}

      {type !== 'loading' && (
        <div className={'flex flex-col items-stretch gap-6'}>
          <p className={'c-h7 text-[#1a1a1a]'}>{title}</p>
          <p className={'c-b4 text-txt-secondary'}>{message}</p>
        </div>
      )}

      {type !== 'loading' && (
        <svg
          onClick={() => rht.dismiss(id)}
          className={'absolute right-20 top-24 cursor-pointer'}
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.4714 3.52859C4.21105 3.26824 3.78894 3.26824 3.52859 3.52859C3.26824 3.78894 3.26824 4.21105 3.52859 4.4714L7.05716 7.99997L3.52853 11.5286C3.26818 11.7889 3.26818 12.2111 3.52853 12.4714C3.78888 12.7318 4.21099 12.7318 4.47134 12.4714L7.99996 8.94278L11.5286 12.4714C11.7889 12.7318 12.2111 12.7318 12.4714 12.4714C12.7318 12.2111 12.7318 11.789 12.4714 11.5286L8.94277 7.99997L12.4713 4.4714C12.7317 4.21105 12.7317 3.78894 12.4713 3.5286C12.211 3.26825 11.7889 3.26825 11.5285 3.5286L7.99996 7.05716L4.4714 3.52859Z"
            fill="#8B8B8B"
          />
        </svg>
      )}
    </div>
  )
}

function isMessageObject(
  message: Message,
): message is { content: React.ReactNode; title: string } {
  const msg = message || {}
  return Object.hasOwn(msg, 'content') && Object.hasOwn(msg, 'title')
}

function displayToast(
  message: Message,
  type: ToastType,
  opts?: ToastOptions & {
    className?: string
    customIcon?: React.ReactNode
  },
) {
  const { customIcon, className, ...toastOptions } = opts || {}
  const customTitle = isMessageObject(message) ? message.title : undefined
  const content = isMessageObject(message) ? message.content : message

  return rht.custom(
    (t) => (
      <CustomToaster
        id={t.id}
        message={content}
        visible={t.visible}
        type={type}
        className={className}
        customIcon={customIcon}
        customTitle={customTitle}
      />
    ),
    {
      ...defaultOptions,
      ...toastOptions,
    },
  )
}

export const toast = {
  success: (message: Message, opts?: ToastOptions) =>
    displayToast(message, 'success', opts),
  loading: (message: Message, opts?: ToastOptions) =>
    displayToast(message, 'loading', opts),
  error: (message: Message, opts?: ToastOptions) =>
    displayToast(message, 'error', opts),
  info: (message: Message, opts?: ToastOptions) =>
    displayToast(message, 'info', opts),
  warning: (message: Message, opts?: ToastOptions) =>
    displayToast(message, 'warning', opts),
  custom: (
    {
      icon,
      ...message
    }: {
      content: React.ReactNode
      title: string
      icon: React.ReactNode
    },
    opts?: ToastOptions,
  ) =>
    displayToast(message, 'info', {
      ...opts,
      customIcon: icon,
    }),

  async promise<T>(
    p: Promise<T>,
    messages: {
      loading: Message
      success: ((result: T) => Message) | Message
      error: ((e: any) => Message) | Message
    },
    opts?: ToastOptions & {
      className?: string
      customIcon?: React.ReactNode
    },
  ) {
    const loadingId = displayToast(messages.loading, 'loading', {
      ...opts,
      duration: 0,
    })

    try {
      const result = await p
      rht.dismiss(loadingId)
      const successMessage =
        typeof messages.success === 'function'
          ? messages.success(result)
          : messages.success
      displayToast(successMessage, 'success', opts)
      return result
    } catch (error) {
      rht.dismiss(loadingId)

      const errorMessage =
        typeof messages.error === 'function'
          ? messages.error(error)
          : messages.error
      displayToast(errorMessage, 'error', opts)
    }
  },

  dismiss: rht.dismiss,
}
